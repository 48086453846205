import { Button } from "@mui/material";
import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";
import "./App.css";
import { Bids } from "./components/Bids/Bids.tsx";
import { Header } from "./components/Header/Header.tsx";
import { Cards } from "./components/Cards/Cards.tsx";
import { Interface } from "./components/Interface/Interface.tsx";
import { Sequence } from "./components/Sequence/Sequence.tsx";
import { Settings } from "./components/Settings/Settings.tsx";
import { Tag } from "./components/Tag/Tag.tsx";
import {
  ITest,
  resetLocalStorage,
  TestType,
  santizeId,
  checkIfBadgeActive,
  emptyStats,
} from "./helpers.ts";
import { useTest } from "./hooks/useTest.ts";
import { ComplexSequence } from "./components/Sequence/ComplexSequence.tsx";
import { AllTestsDrawer } from "./components/Drawer/AllTestsDrawer.tsx";
import { YourResultsDrawer } from "./components/Drawer/YourResultsDrawer.tsx";
import { Loading } from "./components/Loading/Loading.tsx";
import {
  decrement,
  increment,
  setTest,
  setAllTests,
  setPossibleTags,
  setFilters,
  setPoints,
  setEfficiency,
} from "./redux/biddingSlice.ts";
import type { RootState } from "./redux/store.ts";
import { useSelector, useDispatch } from "react-redux";

//TODO: #refactor: move statistics from Bids to separate component
//TODO: #refactor: add data validation rules to sheet
//TODO: #refactor: make sheet private
//TODO: #feature-request: add levels and filtering by level
//TODO: #feature-request: disable bids lower than the last bid
//TODO: #feature-request: show comparison to others

function App() {
  //   const southCards = useSelector(
  //     (state: RootState) => state.bidding.southCards
  //   );
  const { drawnTest, southCards, southCardsPC } = useSelector(
    (state: RootState) => state.bidding
  );
  const dispatch = useDispatch();
  // Settings
  //   const [isDrawerOpen, setIsDrowerOpen] = useState(false);
  //   const [filters, setFilters] = useState<TestType[]>(Object.keys(TestType));

  // Tests:
  const [rawTestData, setRawTestData] = useState<ITest[]>([]);
  const [loading, setLoading] = useState(true);
  const [testData, setTestData] = useState<ITest[]>([]); //filtered
  //   const [randomTest, setRandomTest] = useState<ITest>([]); //picked test
  //   const [tested, setTested] = useState(false);
  const ref = useRef();
  // const bidsSpace = useRef(null)

  //   const drawRandomTest = () => {
  //     ref?.current && ref?.current?.reset();
  //     setRandomTest(testData[`${Math.floor(Math.random() * testData?.length)}`]);
  //   };

  useEffect(() => {
    // console.log(drawnTest, "drawnTest in app");
  }, [drawnTest]);
  useEffect(() => {
	// console.log(southCards, "southCards in app");
	southCards && setLoading(false)
  }, [southCards]);
  //   const {
  //     // southCards,
  //     testSequence,
  //     isComplex,
  //     isLoading,
  //     correctAnswers,
  //     halfAnswers,
  //     depth,
  //     reversedDepth,
  //     getAnswersForTestId,
  //     getPC,
  //     incrementDepth,
  //     complexAnswers,
  //   } = useTest(randomTest, drawRandomTest);

  // Other:
  const [versionClicks, setVersionClicks] = useState(0);
  const [nameClicks, setNameClicks] = useState(0);
  const [devMode, setDevMode] = useState(false);

  const [testsToRedo, setTestsToRedo] = useState(
    JSON.parse(localStorage.getItem("testsToRedo")) || []
  );

  //   const addToFilters = (type: TestType) => {
  //     setFilters((prev) => [...prev, type]);
  //   };

  //   const removeFromFilters = (type: TestType) => {
  //     setFilters((prev) => [...prev].filter((i) => i !== type));
  //   };

  useEffect(() => {
    if (rawTestData?.length === 0) return;
    // const filteredTests = rawTestData.filter((t) => filters.includes(t?.tag));
    //    dispatch(setAllTests(rawTestData))
  }, [rawTestData]);

  useEffect(() => {
    if (versionClicks > 7) {
      resetLocalStorage();
    }
  }, [versionClicks]);

  //   useEffect(() => {
  //     if (nameClicks > 7) {
  //       setDevMode(true);
  //     }
  //   }, [nameClicks]);

  //   useEffect(() => {
  // 	console.log("southCards ue App", southCards);
  //   }, [southCards]);

  const LOCAL = "12zVJBhyBl3M_tGZCGp9AEJoWwRhB3yS0JklcRTuVA1Q"; //Sekwencje testowe
  const PROD = "1zp76Q-Qp-Nb4n3DMaXMSL_OehtTi8ufaoI8Am3w773A"; //Testy

  const ENV_TEST = "1DNtag5ko90ISm8LmKWcfbIbLz2N3XLhsNsVfAp9_XAY"; //Testy ENV TEST
  const ENV_STAGE = "1ss8F_v90-pPMbpnYexQRpLD8KrydEslYXuhh3BhqtvU"; //Testy ENV STAGE
  const ENV_PROD = "1YFNHp6H1gee6C-LdGwhzusBL88F0Fef_e6AeQO3bj74"; //Testy ENV PROD

  const getStoredResult = (id): 0 | 0.5 | 1 | null => {
    const half = JSON.parse(localStorage.getItem("half")) || [];
    const good = JSON.parse(localStorage.getItem("good")) || [];
    const bad = JSON.parse(localStorage.getItem("bad")) || [];
    if (bad.includes(id)) {
      return 0;
    } else if (half.includes(id)) {
      return 0.5;
    } else if (good.includes(id)) {
      return 1;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const points = JSON.parse(localStorage.getItem("points")) || 0;
    const half = JSON.parse(localStorage.getItem("half")) || 0;
    const good = JSON.parse(localStorage.getItem("good")) || 0;
    const bad = JSON.parse(localStorage.getItem("bad")) || 0;
    dispatch(setPoints(points));

    const efficiency = Math.floor(
      ((half.length + good.length) / (half.length + good.length + bad.length)) *
        100
    );

    dispatch(setEfficiency(efficiency));
    Papa.parse(
      `https://docs.google.com/spreadsheets/d/${ENV_STAGE}/pub?output=csv`,
      {
        download: true,
        header: true,
        complete: (results) => {
          console.log(results?.data[0]["possible-tags"]);
          let possibleTags = [];
          let filters = ["level-1", "level-2", "level-3"];
          let isEndOfPossibleTags = false;
          let i = 0;
          while (!isEndOfPossibleTags && i < results?.data.length) {
            const item = results?.data[i]["possible-tags"];
            const itemId = santizeId(item);
            const tagStorage =
              JSON.parse(localStorage.getItem(itemId)) || emptyStats;
            const isBadgeActive = checkIfBadgeActive(
              tagStorage.right,
              tagStorage.all
            );
            if (item) {
              possibleTags.push({
                text: item,
                id: itemId,
                isBadgeActive: isBadgeActive,
                percent: Math.floor((tagStorage.right / tagStorage.all) * 100),
                all: tagStorage.all,
              });
              filters.push(itemId);
              i++;
            } else {
              isEndOfPossibleTags = true;
            }
          }
          dispatch(setPossibleTags(possibleTags));
          dispatch(setFilters(filters));
          dispatch(
            setAllTests(
              results?.data?.map((dataItem) => {
                return {
                  id: dataItem.id,
                  tag: dataItem.tag,
                  sequence: dataItem.sequence,
                  sequence1: dataItem.sequence1,
                  sequence2: dataItem.sequence2,
                  sequence3: dataItem.sequence3,
                  sequence4: dataItem.sequence4,
                  spades: dataItem.spades,
                  hearts: dataItem.hearts,
                  diamonds: dataItem.diamonds,
                  clubs: dataItem.clubs,
                  answer: dataItem.answer,
                  answer1: dataItem.answer1,
                  answer2: dataItem.answer2,
                  answer3: dataItem.answer3,
                  answer4: dataItem.answer4,
                  halfAnswers: dataItem?.halfAnswers,
                  halfAnswers1: dataItem?.halfAnswers1,
                  halfAnswers2: dataItem?.halfAnswers2,
                  halfAnswers3: dataItem?.halfAnswers3,
                  halfAnswers4: dataItem?.halfAnswers4,
                  level: dataItem?.level,
                  result: getStoredResult(dataItem?.id),
                  vulnerability: dataItem?.vulnerability,
                };
              })
            )
          );

          const drawnTestId = `test-${Math.floor(
            Math.random() * results?.data?.length
          )}`;
          dispatch(setTest(drawnTestId));
        },
      }
    );
  }, []);

  //   useEffect(() => {
  //     dispatch(drawRandomTest());
  //   }, [testData]);

  //   const handleInputChange = (e) => {
  //     drawSpecificTest(e.target.value);
  //   };

  //   const handleRedo = (t) => {
  //     console.log("t", t);
  //     const id = "bids-space";
  //     const yOffset = -40;
  //     const element = document.getElementById(id);
  //     const y =
  //       element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //     window.scrollTo({ top: y, behavior: "smooth" });
  //     drawSpecificTest(t);
  //   };

  //   const drawSpecificTest = (number) => {
  //     if (!number || number > rawTestData?.length || !Number.parseFloat(number))
  //       return;
  //     ref?.current && ref?.current.reset();
  //     setRandomTest(rawTestData[number - 1]);
  //     if (rawTestData[number - 1]?.id === randomTest?.id) {
  // 	  setTested(false);
  // 	  console.log("drawSpecificTest")
  //       getAnswersForTestId();
  //     }
  //   };

  //   useEffect(() => {
  //     const tests =
  //       localStorage["testsToRedo"] &&
  //       JSON.parse(localStorage.getItem("testsToRedo"));
  //     setTestsToRedo(tests);
  //   }, [tested]);

  return (
    <div className="App">
		{loading && <Loading />}
      <Header />
      <div className="wrapper">
        <AllTestsDrawer />
        <YourResultsDrawer />
        {/* <Interface
          reshuffle={() => {
            dispatch(drawRandomTest());
            console.log("draw");
          }}
          //   openSettings={() => setIsDrowerOpen(true)}
        /> */}

        {/* <Settings
          isDrawerOpen={isDrawerOpen}
          handleClose={() => setIsDrowerOpen(false)}
          filters={filters}
          addToFilters={addToFilters}
          removeFromFilters={removeFromFilters}
        /> */}
        {/* 
        <h1 className="title">Jak odpowiesz na poniższą sekwencję z kartą:</h1>
        {testData?.length === 0 && !!rawTestData?.length && !isLoading && (
          <p className="big">Żadne sekwencje nie spełniają kryteriów</p>
        )} */}
        {/* {isComplex ? (
          <ComplexSequence
            reversedDepth={reversedDepth}
            testSequence={testSequence}
            depth={depth}
            complexAnswers={complexAnswers}
          />
        ) : (
		)} */}
        <div className="seq-bids">
          {/* <Sequence/>  */}
          <ComplexSequence />
          <Bids />
        </div>
        <div className="test-info">
          {!!drawnTest?.id && (
            <p className="sequence-number">
              Test #{drawnTest?.id.replace(/\D/g, "")}
              {/* {!!drawnTest?.tag && tested && (
              <Tag tag={drawnTest?.tag} customClass={`tag-title`} />
            )} */}
            </p>
          )}
          {/* {southCardsPC && <span className="points">, {southCardsPC} PC</span>} */}
        </div>
        <Cards cards={southCards || Array.from({ length: 13 })} />
      </div>
      {/* {!!testsToRedo?.length && (
        <div className="redo-wrapper">
          <p className="big">Testy do poprawy: </p>
          <div className="redo-items">
            {testsToRedo.map((t) => {
              return (
                <Button
                  variant="text"
                  className="redo-item"
                  onClick={() => {
                    handleRedo(t);
                  }}
                >{`test#${t}`}</Button>
              );
            })}
          </div>
        </div>
      )} */}
      <div className="footer">
        <button
          onClick={() => {
            setTimeout(() => {
              setVersionClicks(0);
            }, 5000);
            setVersionClicks((prevValue) => prevValue + 1);
          }}
          className="version"
        >
          v{process.env.REACT_APP_VERSION}
        </button>
        <div>
          {/* {devMode && (
            <input
              className="test-input"
              placeholder="test number"
              onChange={handleInputChange}
              type="number"
            />
          )} */}
          <p
            // onClick={() => {
            //   setTimeout(() => {
            //     setNameClicks(0);
            //   }, 5000);
            //   setNameClicks((prevValue) => prevValue + 1);
            // }}
            className="footer_desc"
          >
            kod:{" "}
            <a
              className="footer__link"
              target="_blank"
              href="https://www.linkedin.com/in/magdapaluch/"
            >
              Magda Paluch
            </a>{" "}
            , brydż: Szymon Ostrowski
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
