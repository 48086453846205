import { BidExplanation } from "../../helpers.ts";
import BrokenHeart from "../BrokenHeart.tsx";
import { SequenceItem } from "../Sequence/SequenceItem.tsx";
import "./Explanation.css";
interface ExplanationProps {
  result: 0 | 0.5 | 1;
  answer: BidExplanation[];
}

export const Explanation = (props: ExplanationProps) => {
  const { result, answer } = props;

  return (
    <div className="explanation">
      <p className="tested__text">PRAWIDŁOWE ODPOWIEDZI</p>
      {/* <div><BrokenHeart /></div> */}
      <div className="explanation__wrapper">
        {answer.map((a, i) => {
          return (
            <div className="explanation__item" key={a?.enum + a?.number}>
              <span key={a?.string}>{i > 0 ? " lub " : ""}</span>
              <SequenceItem
                key={a?.enum + a?.number}
                item={a}
                index={0}
                classes={`sequence-item sequence-item-show  sequence-item--${a?.enum} sequence-item--explanation`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
