import { useState } from "react";
import { useSelector } from "react-redux";
import { bidStringToBidEmoji, suitsEnum, suitsHierarchy } from "../../helpers.ts";
import type { RootState } from "../../redux/store.ts";
import "./BiddingUI.css";

interface BidUIProps {
  handleUserBid: (b) => void;
  //   handleBidTrump: (t: Trump) => void;
}
const biddingNumbers = [
  { id: 1, text: "1" },
  { id: 2, text: "2" },
  { id: 3, text: "3" },
  { id: 4, text: "4" },
  { id: 5, text: "5" },
  { id: 6, text: "6" },
  { id: 7, text: "7" },
];

const biddingTrumps = [
  { id: suitsEnum.CLUBS, text: "trefl" },
  { id: suitsEnum.DIAMONDS, text: "karo" },
  { id: suitsEnum.HEARTS, text: "kier" },
  { id: suitsEnum.SPADES, text: "pik" },
  { id: "BA", text: "BA" },
];

export const BiddingUI = (props: BidUIProps) => {
  const { wholeSequence } = useSelector((state: RootState) => state.bidding);

  const [localBidNumber, setLocalBidNumber] = useState<number | null>(null);
  //   const [localBidTrump, setLocalBidTrump] = useState<Trump>();

  const seqIndex = wholeSequence.findIndex((item) => {
    return item?.string === "?";
  });

  const seq = [...wholeSequence];
  const pastSeq = seq.slice(0, seqIndex);
//   console.log("pastSeq", pastSeq);

  const isNormalBid = (bid) => {
    return Object.values(suitsEnum).includes(bid) || bid === "BA";
  };

  const currentHighestItem = pastSeq
    .filter((item) => {
      return isNormalBid(item?.enum);
    })
    .slice(-1)[0];
  const lowestPossibleNumber =
    currentHighestItem?.enum === "BA"
      ? Number(currentHighestItem?.number) + 1
      : currentHighestItem?.number;

  const lowestPossibleTrump =
    currentHighestItem?.enum === "BA"
      ? -1
      : suitsHierarchy[currentHighestItem?.enum];

//   console.log("lowestPossibleTrump", lowestPossibleTrump);
  const canUseKtr = () => {
    return (
      isNormalBid([...pastSeq].slice(-1)[0]?.enum) ||
      (isNormalBid([...pastSeq].slice(-3)[0]?.enum) &&
        [...pastSeq].slice(-2)[0]?.enum === 0 &&
        [...pastSeq].slice(-1)[0]?.enum === 0)
    );
  };
  const canUseRktr = () => {
    return (
      [...pastSeq].slice(-1)[0]?.enum === -1 ||
      ([...pastSeq].slice(-3)[0]?.enum === -1 &&
        [...pastSeq].slice(-2)[0]?.enum === 0 &&
        [...pastSeq].slice(-1)[0]?.enum === 0)
    );
  };
  //   console.log("canUseKtr", canUseKtr());
  return (
    <div className="biddingUI">
      <div className="row row-numbers">
        {biddingNumbers.map((number) => {
          return (
            <button
              key={number.text}
              disabled={number.id < lowestPossibleNumber}
              className={`biddingUI_button ${
                number.id === localBidNumber ? "chosen" : ""
              }`}
              onClick={() => {
                setLocalBidNumber(number.id);
              }}
            >
              {number.text}
            </button>
          );
        })}
      </div>
      <div className="row suits">
        {biddingTrumps.map((trump) => {
          return (
            <button
              key={trump.id}
              disabled={
                !localBidNumber ||
                (suitsHierarchy[trump.id] <= lowestPossibleTrump &&
                  localBidNumber == lowestPossibleNumber)
              }
              className={`biddingUI_button`}
              onClick={() => {
                // props.handleBidNumber(localBidNumber);
                // props.handleBidTrump(trump.text);
                setLocalBidNumber(null);
                const a = bidStringToBidEmoji(`${localBidNumber}${trump.text}`);
                props.handleUserBid(a);
              }}
            >
              {trump.id === "BA" ? "BA" : <img src={`./${trump.id}.svg`} />}
            </button>
          );
        })}
      </div>
      <div className="row">
        <button
          className="biddingUI_button biddingUI_button-ktr"
          disabled={!canUseKtr()}
          onClick={() => {
            const a = bidStringToBidEmoji("ktr");
            props.handleUserBid(a);
            // props.handleBidNumber(-1);
            // props.handleBidTrump(a.enum);
          }}
        >
          X
        </button>
        <button
          className="biddingUI_button biddingUI_button-rktr"
          disabled={!canUseRktr()}
          onClick={() => {
            const a = bidStringToBidEmoji("rktr");
            props.handleUserBid(a);
            // props.handleBidNumber(-2);
            // props.handleBidTrump(a.enum);
          }}
        >
          XX
        </button>
        <button
          className="biddingUI_button biddingUI_button-pas"
          onClick={() => {
            const a = bidStringToBidEmoji("pas");
            props.handleUserBid(a);
            // props.handleBidNumber(0);
            // props.handleBidTrump(a.enum);
          }}
        >
          PAS
        </button>
        {/* <button
          disabled={(!localBidNumber || !localBidTrump) && localBidNumber !== 0}
          className="biddingUI_button"
          onClick={() => {
            props.handleBidNumber(localBidNumber);
            props.handleBidTrump(localBidTrump);
            setLocalBidTrump(null);
            setLocalBidNumber(null);
          }}
        >
          Potwierdź
        </button> */}
      </div>
    </div>
  );
};
