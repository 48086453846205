import { useDispatch } from "react-redux";

import {
  setTest,
  closeAllTestsDrawer,
} from "../../redux/biddingSlice.ts";
//TODO: this data should be parsed from the sheet
const levelDic = {
  1: "łatwy",
  2: "średni",
  3: "trudny",
};

export const TestItem = ({ item }) => {
  const dispatch = useDispatch();

  const getHeartResult = (result: 0 | 0.5 | 1 | null) => {
    switch (result) {
      case 0:
        return <img src="./heart-0.svg" />;
      case 0.5:
        return <img src="./heart-05.svg" />;
      case 1:
        return <img src="./heart-1.svg" />;
      default:
        return;
    }
  };

  return (
    <button
      className="testItem"
      onClick={() => {
        dispatch(setTest(item?.id));
        dispatch(closeAllTestsDrawer());
      }}
    >
      <p className="testItem__id"> Test #{item?.id.replace(/\D/g, "")}</p>
      <div className="testItem__details">
        <p className="testItem__level">
          {levelDic[item?.level]?.toUpperCase()}
        </p>
        <div className="testItem__result">{getHeartResult(item?.result)}</div>
      </div>
    </button>
  );
};
