import { suitsEnum } from "../../helpers.ts";
import "./Card.css";

interface CardProps {
  card: string;
  index: number;
}

const suitsDic = {
  D: suitsEnum.DIAMONDS,
  S: suitsEnum.SPADES,
  C: suitsEnum.CLUBS,
  H: suitsEnum.HEARTS,
};

export const Card = (props: CardProps) => {
  const { card, index } = props;
//   console.log(card, index);
  if (!card) return;
  const number = card[0] == "0" ? "10" : card[0];
  return (
    <div
      key={`card-index${card}`}
      className={`card${index === 0 ? " firstCard" : ""}`}
    >
      <img
        style={{ transition: `0.4s opacity ${index}s` }}
        src={"./svg_playing_cards/back.svg"}
        className="cardImage front "
      />
      <div className={`custom-card custom-card--${card[1]}`}>
        <div className="custom-card__top">
          <p className="custom-card__number custom-card__top__number">
            {number}
          </p>
          <img
            className="custom-card__top__suit custom-card__suit"
            src={`./${suitsDic[card[1]]}.svg`}
            alt=""
          />
        </div>
        <div className="custom-card__middle">
          <p className="custom-card__number custom-card__middle__number">
            {number}
          </p>
        </div>
        <div className="custom-card__bottom">
          <p className="custom-card__number custom-card__bottom__number">
            {number}
          </p>
          <img
            className="custom-card__bottom__suit custom-card__suit"
            src={`./${suitsDic[card[1]]}.svg`}
            alt=""
          />
        </div>
      </div>
      {/* <img
        style={{
          opacity: card ? 1 : 0,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          transition: `0.4s opacity ${index}s`,
        }}
        src={`./svg_playing_cards/${card}.svg`}
        className="cardImage front "
      /> */}
    </div>
  );
};
