import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openAllTestsDrawer, openYourResultsDrawer } from "../../redux/biddingSlice.ts";
import type { RootState } from "../../redux/store.ts";
import "./Header.css";
import { getRankDetailsByPoints } from "../../helpers.ts";
export const Header = () => {
  const { points } = useSelector((state: RootState) => state.bidding);
  const [wobbleClass, setWobbleClass] = useState("");
  useEffect(() => {
    setWobbleClass("wobble");
    setTimeout(() => {
      setWobbleClass("");
    }, 1000);
  }, [points]);

  const rankDetails = getRankDetailsByPoints(points);
  const dispatch = useDispatch();
  return (
    <div className="header">
      <div className="header__inner">
        <img className="header__logo" src="./logo-white-5.svg" />
        <div className="header__items">
          <button
            onClick={() => {
              dispatch(openAllTestsDrawer());
            }}
            className="header__item"
          >
            Lista testów
          </button>
          <button
            onClick={() => {
              dispatch(openYourResultsDrawer());
            }}
            className="header__item header__results"
          >
            Twoje wyniki
          </button>
          <button
            className="header__user"
            onClick={() => {
              dispatch(openYourResultsDrawer());
            }}
          >
            <img className="header__crown" src={`./${rankDetails.img}.svg`} />
            <div className={`header__user-points-wrapper ${wobbleClass}`}>
              <p className="header__user-points">{points}</p>
              <img className="header__diamonds" src="./DIAMONDS.svg" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
