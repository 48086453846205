import "./Sequence.css";
import LoopIcon from "@mui/icons-material/Loop";

import { memo, useMemo, useEffect, useState } from "react";
import {
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
} from "@mui/material";
import {
  BidExplanation,
  bidStringToBidEmoji,
  suitsEnum,
  santizeId,
} from "../../helpers.ts";
import { Seats } from "./Seats.tsx";
import { useSelector } from "react-redux";
import type { RootState } from "../../redux/store.ts";
import { SequenceItem } from "./SequenceItem.tsx";

interface ComplexSequenceProps {
  testSequence: string[];
  reversedDepth: number;
  depth: number;
  complexAnswers: BidExplanation[];
}

export const ComplexSequence = (props: ComplexSequenceProps) => {
  const { drawnTest, currentDepth, wholeSequence, testDepth } = useSelector(
    (state: RootState) => state.bidding
  );

  const allBids = wholeSequence;
  //   const { testSequence, reversedDepth, depth, complexAnswers } = props;

  //   const testSequence = drawnTest?.wholeSeqence;
  const depth = drawnTest?.depth;
  const currentAnswers = drawnTest?.currentAnswers;
  // const currentAnswers = reversedDepth;
  const currentHalfAnswers = drawnTest?.currentHalfAnswers;
  const [isInitLoading, setisInitLoading] = useState(true);
  const [wholeSeqAnimation, setWholeSeqAnimation] = useState(false);

  const classForSide = {
    0: "W",
    1: "S",
    2: "E",
    3: "N",
  };

  const classForStarted = {
    0: "W",
    1: "S",
    2: "E",
    3: "N",
  };

  const initAnimate = () => {
    // console.log("run init animate");
    setisInitLoading(true);
    setTimeout(() => {
      setisInitLoading(false);
    }, 500);
  };

  //   const handleReanimate = () => {
  //     animate();
  //     setWholeSeqAnimation(true);
  //     setTimeout(() => {
  //       setWholeSeqAnimation(false);
  //     }, 500 * testSequence?.length);
  //   };

  useEffect(() => {
    // console.log(isInitLoading);
  }, [isInitLoading]);

  useEffect(() => {
    // console.log("allBids", allBids);
    // console.log("wholeSequence", wholeSequence);
    // console.log(
    //   "currentDepth < testDepth",
    //   currentDepth,
    //   testDepth,
    //   currentDepth < testDepth
    // );
    if (currentDepth === 0) {
      initAnimate();
    }
    // console.log("changed all bids");
  }, [wholeSequence]);

  const started = allBids ? classForStarted[allBids.length % 4] : null;

  const getColorByType = (type: string): string => {
    switch (type) {
      case "PAS":
        return "pass-green";
      case "X":
        return "ktr-red";
      case "XX":
        return "rktr-blue";
      default:
        return "";
    }
  };

  const orderedBidsWithClasses = useMemo(() => {
    if (!allBids) return null;
    return allBids.map((item, index) => {
    //   console.log(item);
      const x = ((allBids.length - 1) % 4) + currentDepth * 4;
      const y = index + 4 - (allBids.length % 4);
      const z = allBids.length % 4 || 4;
      const seq0123 = index < allBids.length % 4 ? index : y;
      //   console.log(testSequence);
      //   console.log(depth, index, x, allBids.length % 4, currentDepth);
      //   console.log(item);

      const classes = `sequence-item 
	  seq0123-${seq0123}
	  ${item?.enum + item?.number + drawnTest?.id + index}
	  index-${index}
	  shouldShow-${index < z + 4 * currentDepth}
	  ${classForSide[(allBids.length - index) % 4]}-bid 
	  ${getColorByType(item?.string)}
	  sequence-item--${item?.enum}
	  sequence-item-depth-${Math.floor(index / 4)}
	  complex-sequence-item-${!wholeSeqAnimation ? seq0123 % 4 : ""} 
	  ${
      !isInitLoading && index < z + 4 * currentDepth ? "sequence-item-show" : ""
    }`;
      return (
        <SequenceItem
          key={item?.enum + item?.number + drawnTest?.id + index}
          item={item}
          index={index}
          classes={classes}
        />
      );
    });
  }, [allBids, currentDepth, isInitLoading]);

  return (
    <>
      <div
        className={`bids-space current-depth-${currentDepth} v-${santizeId(
          drawnTest?.vulnerability
        )}`}
        id="bids-space"
      >
        <div className="test-sequence">
          <Seats started={started} />
          {!!allBids && !!orderedBidsWithClasses && orderedBidsWithClasses}
        </div>
      </div>
    </>
  );
};
