import { useDispatch, useSelector } from "react-redux";
import { getRankDetailsByPoints } from "../../helpers.ts";
import { closeYourResultsDrawer, Tag } from "../../redux/biddingSlice.ts";
import { Badge } from "./Badge.tsx";
import "./Drawer.css";
import { Drawer } from "./Drawer.tsx";
import type { RootState } from "./redux/store.ts";


export const YourResultsDrawer = () => {
  const {
    filteredTests,
    possibleTags,
    filters,
    yourResultsDrawerOpen,
    points,
    efficiency,
  } = useSelector((state: RootState) => state.bidding);
  const dispatch = useDispatch();

  //TODO: type this
  const rankDetails = getRankDetailsByPoints(points);

  const activeBadgesCount = possibleTags.filter((t) => t.isBadgeActive).length;

  return (
    <Drawer
      isOpened={yourResultsDrawerOpen}
      onClose={() => dispatch(closeYourResultsDrawer())}
    >
      <div className="yourResults__wrapper">
        <p className="yourResults__title">Twoja ranga:</p>
        <img
          className="yourResults__crown"
          src={`./${rankDetails.img}.svg`}
          alt=""
        />
        <div className="yourResults__rank-wrapper">
          <img className="yourResults__heart" src={`./HEARTS.svg`} alt="" />
          <p className="yourResults__rank">{rankDetails.text}</p>
          <img className="yourResults__heart" src={`./HEARTS.svg`} alt="" />
        </div>
        <div className="yourResults__details">
          <p className="yourResults__details-paragraph">
            Do zdobycia kolejnej rangi brakuje Ci {rankDetails.toNextRank}
            <img
              className="yourResults__diamond"
              src={`./DIAMONDS.svg`}
              alt=""
            />
          </p>
          {/* <p className="yourResults__details-paragraph">
            Każdy dzień bez aktywności to -10
            <img
              className="yourResults__diamond"
              src={`./DIAMONDS.svg`}
              alt=""
            />
          </p> */}
        </div>
        <p className="yourResults__title  yourResults__title--mb0">Twoja skuteczność:</p>
        <p className="yourResults__desc">
          Procent dobrze wykonanych unikalnych testów
        </p>
        <p className="yourResults__efficiency">{efficiency}%</p>
        <p className="yourResults__title yourResults__title--mb0">
          Twoje kompetencje:
        </p>
        <p className="yourResults__desc">
          Przynajmniej 50% i 5 testów z danej kategorii
        </p>
        <p className="yourResults__desc yourResults__desc--yellow">
          {activeBadgesCount}/{possibleTags.length}
        </p>
        <div className="badges">
          {possibleTags.map((tag: Tag) => (
            <Badge tag={tag} />
          ))}
        </div>
      </div>
    </Drawer>
  );
};
