import "./Loading.css";

export const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-img-wrapper">
        <img src="./loading-logo.svg" alt="" />
        <img className="loading-suit loading-suit--HEARTS" src="./HEARTS.svg" alt="" />
        <img className="loading-suit loading-suit--DIAMONDS" src="./DIAMONDS.svg" alt="" />
        <img className="loading-suit loading-suit--CLUBS" src="./CLUBS.svg" alt="" />
      </div>
    </div>
  );
};
