export const Badge = ({ tag }) => {
  function camelCase(str) {
    // converting all characters to lowercase
    let ans = str.toUpperCase();

    // Returning string to camelcase
    return ans
      .split("")
      .reduce((s, c) => s + (c.charAt(0).toLowerCase() + c.slice(1)));
  }
  return (
    <div className={`badge__wrapper  ${tag.isBadgeActive ? "" : "inactive"}`}>
      <p className={`badge`}>
        {camelCase(tag.text)}
      </p>
      <img className="badge__image" src="./badge-bg.svg" alt="" />
      <p className="badge__details">
        {tag.percent || 0}%, {tag.all}
      </p>
    </div>
  );
};
