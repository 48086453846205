export const enum suitsEnum {
  "SPADES" = "SPADES",
  "HEARTS" = "HEARTS",
  "DIAMONDS" = "DIAMONDS",
  "CLUBS" = "CLUBS",
}
export const enum suitsHierarchy {
  "CLUBS",
  "DIAMONDS",
  "HEARTS",
  "SPADES",
  "BA",
}

export type Trump = suitsEnum | "BA";

export const higherCardsArray = ["JACK", "QUEEN", "KING", "ACE"];

export const higherCardsMap = {
  JACK: { hierarchyValue: 11, pointCardsValue: 1 },
  QUEEN: { hierarchyValue: 12, pointCardsValue: 2 },
  KING: { hierarchyValue: 13, pointCardsValue: 3 },
  ACE: { hierarchyValue: 14, pointCardsValue: 4 },
};

export const coloredCards = (cards) => {
  const spades = cards.filter((card) => card.suit === suitsEnum.SPADES);
  const hearts = cards.filter((card) => card.suit === suitsEnum.HEARTS);
  const clubs = cards.filter((card) => card.suit === suitsEnum.CLUBS);
  const diamonds = cards.filter((card) => card.suit === suitsEnum.DIAMONDS);
  return { spades, hearts, clubs, diamonds };
};

const pointCardsValue = {
  J: 1,
  Q: 2,
  K: 3,
  A: 4,
};

export const checkIfBadgeActive = (right, all): boolean => {
  return right / all > 0.5 && all >= 5;
};

export const getPointCount = (cards, isSingle) => {
  const cardsToCount = cards
    .trim()
    .replace(/(10)/, "x")
    .split("")
    .filter((card) => card !== "x");
  if (!cardsToCount || !cardsToCount.length) return null;
  return cardsToCount.reduce((acc, obj) => {
    const value =
      isSingle && obj !== "A" ? pointCardsValue[obj] - 1 : pointCardsValue[obj];
    return acc + value;
  }, 0);
};

export const getAnswersArray = (ans: string) => {
  return ans.split(",").map((a) => {
    const ans = a.trim();
    // if (!bidStringToBidEmoji(ans)) {
    //   resetAll();
    //   drawRandomTest();
    //   console.log("prevent error in answers", randomTest.id);
    //   return;
    // }
    return bidStringToBidEmoji(ans);
  });
};

export const isSingle = (cards) => {
  switch (cards.length) {
    case 1:
      return true;
    case 0:
    default:
      return false;
  }
};
export interface BidExplanation {
  number: number;
  trump: Trump;
  bidString: string;
  explanationString: string;
  PC: number;
}

export const colorsLibrary = new Map();

colorsLibrary.set(suitsEnum.SPADES, "♠️");
colorsLibrary.set(suitsEnum.HEARTS, "♥️");
colorsLibrary.set(suitsEnum.DIAMONDS, "♦️");
colorsLibrary.set(suitsEnum.CLUBS, "♣️");

export const orderCardsNeatly = (s, h, c, d) => {
  if (!h.length) return [...s, ...d, ...c];
  if (!c.length) return [...h, ...s, ...d];
  return [...s, ...h, ...d, ...c];
};

export interface ITest {
  id: string;
  tag: string;
  sequence: string;
  spades: string;
  hearts: string;
  diamonds: string;
  clubs: string;
  answer: string;
  halfAnswers: string;
}

export const trumpsDictionary = {
  trefl: { emoji: "♣️", enum: suitsEnum.CLUBS },
  karo: { emoji: "♦️", enum: suitsEnum.DIAMONDS },
  kier: { emoji: "♥️", enum: suitsEnum.HEARTS },
  pik: { emoji: "♠️", enum: suitsEnum.SPADES },
  BA: { emoji: "BA", enum: "BA" },
  pas: { emoji: "pas", enum: "pas" },
  ktr: { emoji: "ktr", enum: "ktr" },
  rktr: { emoji: "rktr", enum: "rktr" },
};

export const specialBids = {
  pas: { value: 0, string: "PAS" },
  ktr: { value: -1, string: "X" },
  rktr: { value: -2, string: "XX" },
  "?": { value: null, string: "?" },
};

export enum TestType {
  DRURY = "DRURY",
  MAGISTER = "MAGISTER",
  ODWROTKA = "ODWROTKA",
  JEDNOSTRONNA = "JEDNOSTRONNA",
  DWUSTRONNA = "DWUSTRONNA",
  BLACKWOOD = "BLACKWOOD",
}

export const bidStringToBidEmoji = (
  bid
): { string: string; enum: suitsEnum; number: string } => {
  const trimmedBid = bid.trim();
  if (Object.keys(specialBids).includes(trimmedBid))
    return {
      string: specialBids[trimmedBid].string,
      enum: specialBids[trimmedBid].value,
      number: null,
    };
  const number = trimmedBid.charAt(0);
  const trump = trimmedBid.substring(1);
  const emoji = trumpsDictionary[trump]?.emoji;
  return emoji
    ? {
        string: `${number}${emoji}`,
        enum: trumpsDictionary[trump].enum,
        number: number,
      }
    : null;
};

export const emptyStats = { right: 0, all: 0 };

export const resetLocalStorage = () => {
  localStorage.setItem("good", JSON.stringify([]));
  localStorage.setItem("bad", JSON.stringify([]));
  localStorage.setItem("half", JSON.stringify([]));
  localStorage.setItem("points", JSON.stringify(0));
  localStorage.setItem("strefa-szlemowa", JSON.stringify(emptyStats));
  localStorage.setItem("magister", JSON.stringify(emptyStats));
  localStorage.setItem("rebidy", JSON.stringify(emptyStats));
  localStorage.setItem("odwrotka", JSON.stringify(emptyStats));
  localStorage.setItem("odpowiedzi", JSON.stringify(emptyStats));
  localStorage.setItem("dwustronna-w-obronie", JSON.stringify(emptyStats));
  localStorage.setItem("otwarcia", JSON.stringify(emptyStats));
  localStorage.setItem("dwustronna-po-interwencji", JSON.stringify(emptyStats));
  localStorage.setItem("drury", JSON.stringify(emptyStats));
  window.location.reload();
};

const setTagStats = (tag, result, isFinished) => {
  console.log(tag, result, isFinished);
  const tagId = santizeId(tag);
  const tagStorage = JSON.parse(localStorage.getItem(tagId)) || emptyStats;
  if (isFinished || result === 0 || result === 1) {
    switch (result) {
      case 0:
        tagStorage.all = tagStorage.all += 1;
        break;
      case 0.5:
        tagStorage.all = tagStorage.all += 1;
        break;
      case 1:
        tagStorage.all = tagStorage.all += 1;
        tagStorage.right = tagStorage.right += 1;
        break;
    }
    localStorage.setItem(tagId, JSON.stringify(tagStorage));
  }
  return { tagStorage, tagId };
};

const RANK_DETAILS = {
  jack: {
    text: "Walet",
    img: "jack-crown",
    points: 0,
  },
  queen: {
    text: "Królowa",
    img: "queen-crown",
    points: 20,
  },
  king: {
    text: "Król",
    img: "king-crown",
    points: 50,
  },
  joker: {
    text: "Joker",
    img: "joker-crown",
    points: 100,
  },
};

export const getRankDetailsByPoints = (points: number) => {
  if (points >= RANK_DETAILS.joker.points) {
    return {
      ...RANK_DETAILS.joker,
      toNextRank: 0,
    };
  } else if (points >= RANK_DETAILS.king.points) {
    return {
      ...RANK_DETAILS.king,
      toNextRank: RANK_DETAILS.joker.points - points,
    };
  } else if (points >= RANK_DETAILS.queen.points) {
    return {
      ...RANK_DETAILS.queen,
      toNextRank: RANK_DETAILS.king.points - points,
    };
  } else {
    return {
      ...RANK_DETAILS.jack,
      toNextRank: RANK_DETAILS.queen.points - points,
    };
  }
};

export const updateStats = (
  id: string,
  result: 0 | 0.5 | 1,
  tag,
  isFinished,
  level
) => {
  //   console.log(id, result, tag);
  const half = JSON.parse(localStorage.getItem("half")) || [];
  const good = JSON.parse(localStorage.getItem("good")) || [];
  const bad = JSON.parse(localStorage.getItem("bad")) || [];
  let points = parseInt(JSON.parse(localStorage.getItem("points"))) || 0;
  localStorage.setItem("lastTestDate", JSON.stringify(new Date().toJSON()));

  const removeFromOtherArrays = (result, id) => {
    const indexOfGood = good.indexOf(id);
    const indexOfHalf = half.indexOf(id);
    const indexOfBad = bad.indexOf(id);

    switch (result) {
      case 0:
        indexOfGood !== -1 && good.splice(indexOfGood, 1);
        indexOfHalf !== -1 && half.splice(indexOfHalf, 1);
        break;
      case 0.5:
        indexOfGood !== -1 && good.splice(indexOfGood, 1);
        indexOfBad !== -1 && half.splice(indexOfBad, 1);

        break;
      case 1:
        indexOfBad !== -1 && bad.splice(indexOfBad, 1);
        indexOfHalf !== -1 && half.splice(indexOfHalf, 1);
        break;
    }
  };

  removeFromOtherArrays(result, id);
  console.log(level)
  switch (result) {
    case 0:
      if (bad.indexOf(id) === -1) bad.push(id);
      level == 1 ? (points -= 10) : (points -= 4);
      break;
    case 0.5:
      if (half.indexOf(id) === -1) half.push(id);
      points += 1;
      break;
    case 1:
      points += parseInt(level);
      if (good.indexOf(id) === -1) good.push(id);
      break;
  }
  localStorage.setItem("half", JSON.stringify(half));
  localStorage.setItem("good", JSON.stringify(good));
  localStorage.setItem("bad", JSON.stringify(bad));
  localStorage.setItem("points", JSON.stringify(points));
  //   localStorage.setItem("lastTestDate", JSON.stringify(new Date().toJSON()));

  const { tagStorage, tagId } = setTagStats(tag, result, isFinished);
  const allLength = half.length + good.length + bad.length;
  const efficiency =
    Math.floor(((half.length + good.length) / allLength) * 100) || allLength;

  return { points, tagId, tagStorage, efficiency };
};

export const getBidByValue = (v) => {
  switch (v) {
    case 0:
      return " PAS";
    case -1:
      return " kontra";
    case -2:
      return " rekontra";
  }
};

export const testTypeToColor = (testType: TestType) => {
  switch (testType) {
    case TestType.BLACKWOOD:
      return "#370617";
    case TestType.DRURY:
      return "#d90429";
    case TestType.DWUSTRONNA:
      return "#38b000";
    case TestType.JEDNOSTRONNA:
      return "#ff006e";
    case TestType.MAGISTER:
      return "#3a86ff";
    case TestType.ODWROTKA:
      return "#f77f00";
  }
};

export const santizeId = (id: string) => {
  return id ? id.replace(/\W/g, "-").toLowerCase() : "";
};
