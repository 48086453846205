import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkIfBadgeActive, updateStats } from "../../helpers.ts";
import Arrow from "../../icons/Arrow.tsx";
import Repeat from "../../icons/Repeat.tsx";
import {
  incrementDepth,
  setAllTests,
  setEfficiency,
  setPoints,
  setTest,
  updatePossibleTagsBadge,
} from "../../redux/biddingSlice.ts";
import type { RootState } from "../../redux/store.ts";
import { BiddingUI } from "../BiddingUI/BiddingUI.tsx";
import { Draw } from "../Drawer/Draw.tsx";
import { Explanation } from "../Explanation/Explanation.tsx";
import { SequenceItem } from "../Sequence/SequenceItem.tsx";

// interface BidsProps {
//   isLoading: boolean;
//   correctAnswers: BidExplanation[] | null;
//   handleBidNumber: (num: number | null) => void;
//   handleBidTrump: (t: Trump | null) => void;
//   bidNumber: number | null;
//   bidTrump: Trump | null;
//   reshuffle: () => void;
//   type: TestType;
//   halfAnswers: BidExplanation[] | null;
//   testId: string;
//   tested: boolean;
//   handleTested: (b: boolean) => void;
//   incrementDepth: () => void;
//   isComplex: boolean;
//   depth: number;
//   reversedDepth: number;
//   complexAnswers: BidExplanation[] | null;
// }

const resultDictionary = {
  1: "Dobrze",
  0: "Źle",
  0.5: "To dobra odpowiedź, ale są lepsze",
};

export const Bids = () => {
  const {
    drawnTest,
    southCards,
    southCardsPC,
    answer,
    currentAnswers,
    currentHalfAnswers,
    isAnsweredCorrectly,
    // handleTested,
    // incrementDepth,
    // isComplex,
    testDepth,
    currentDepth,
    // complexAnswers,
    // reversedDepth,
    filteredTests,
    allTests,
  } = useSelector((state: RootState) => state.bidding);

  const dispatch = useDispatch();
  //   const {
  //     isLoading,
  //     correctAnswers,
  //     reshuffle,
  //     type,
  //     halfAnswers,
  //     testId,
  //     tested,
  //     handleTested,
  //     incrementDepth,
  //     isComplex,
  //     depth,
  //     complexAnswers,
  //     reversedDepth,
  //   } = props;
  const testIdNumber = drawnTest?.id && drawnTest?.id.replace(/\D/g, "");

  const [userBid, setUserBid] = useState(null);
  const [altGood, setAltGood] = useState(false);
  //   const [userBidTrump, setUserBidTrump] = useState<Trump>();
  const [result, setResult] = useState<0 | 0.5 | 1 | null>(null);

  const shouldContinue = currentDepth < testDepth && result === 1;

  const hasFinished = currentDepth === testDepth;

  //   const {
  //     statsDrury,
  //     statsOdwrotka,
  //     statsMagister,
  //     statsBlackwood,
  //     statsJednostronna,
  //     statsDwustronna,
  //     setStats,
  //   } = useStats();

  useEffect(() => {
    reset();
  }, [drawnTest?.id]);

  //   useImperativeHandle(ref, () => ({
  //     reset() {
  //       reset();
  //     },
  //   }));

  const reset = () => {
    setAltGood(false);
    setUserBid(null);
    // handleTested(false);
    // setUserBidTrump(null);
    setResult(null);
  };

  const updateAllTests = (localResult) => {
    const all = [...allTests];
    const suggestedIndex = parseInt(drawnTest?.id.replace(/\D/g, "")) - 1;
    // console.log(suggestedIndex, all[suggestedIndex]?.id === drawnTest?.id);
    // console.log(all[suggestedIndex].result);
    if (all[suggestedIndex]?.id === drawnTest?.id) {
      const copyOfItem = { ...all[suggestedIndex] };
      copyOfItem.result = localResult;
      all[suggestedIndex] = copyOfItem;
      //   all[suggestedIndex].result = localResult;
    } else {
      const index = all.findIndex((item) => item?.id === drawnTest?.id);
      const copyOfItem = { ...all[index] };
      copyOfItem.result = localResult;
      all[index] = copyOfItem;
      //   all[index].result = localResult;
    }
    dispatch(setAllTests(all));
  };

  useEffect(() => {
    // console.log("this", userBid, currentAnswers, currentHalfAnswers);
    if (!currentAnswers || (!userBid && userBid?.number !== 0)) return;
    // // console.log(correctAnswers, userBidNumber, userBidTrump);
    // // const testsToRedo = JSON.parse(localStorage.getItem("testsToRedo"));
    // handleTested(true);
    let localResult = 0.5;

    if (
      currentAnswers &&
      currentAnswers
        .filter((a) => a?.number == userBid?.number)
        .filter((a) => a?.enum == userBid?.enum).length > 0
      //   (isComplex &&
      //     complexAnswers[reversedDepth].number == userBidNumber &&
      //     complexAnswers[reversedDepth].trump == userBidTrump)
    ) {
      //   setStats(type)((prevState) => {
      //     return { right: prevState.right + 1, all: prevState.all + 1 };
      //   });

      const isFirstAnswer =
        currentAnswers[0]?.number == userBid?.number &&
        currentAnswers[0]?.enum == userBid?.enum;
      console.log(
        currentDepth <= testDepth,
        currentAnswers.length,
        !isFirstAnswer
      );
      if (
        currentDepth <= testDepth &&
        currentAnswers.length > 1 &&
        !isFirstAnswer
      ) {
        // two equal answers, only one proceeds

        setAltGood(true);
        localResult = 1;
      } else {
        const userBidFromCurrentAnswers = currentAnswers
          .filter((a) => a?.number == userBid?.number)
          .filter((a) => a?.enum == userBid?.enum)[0];
        dispatch(incrementDepth(userBidFromCurrentAnswers));
        localResult = hasFinished ? 1 : 0.5;
      }
      setResult(1);
      //   reset();
      //   if (testIdNumber) {
      //     if (testsToRedo.filter((a) => a == testIdNumber).length > 0) {
      //       const index = testsToRedo.indexOf(testIdNumber);
      //       if (index > -1) {
      //         testsToRedo.splice(index, 1);
      //       }
      //       localStorage.setItem("testsToRedo", JSON.stringify(testsToRedo));
      //     }
      //   }
    } else if (
      currentHalfAnswers &&
      currentHalfAnswers
        .filter((a) => a?.number == userBid?.number)
        .filter((a) => a?.enum == userBid?.enum).length > 0
    ) {
      setResult(0.5);
      //   setStats(type)((prevState) => {
      //     return { right: prevState.right + 0.5, all: prevState.all + 1 };
      //   });
      //   if (testIdNumber) {
      //     if (testsToRedo.filter((a) => a === testIdNumber).length > 0) {
      //       const index = testsToRedo.indexOf(testIdNumber);
      //       if (index > -1) {
      //         testsToRedo.splice(index, 1);
      //       }
      //       localStorage.setItem("testsToRedo", JSON.stringify(testsToRedo));
      //     }
      //   }
    } else {
      //   setStats(type)((prevState) => {
      //     return { right: prevState.right, all: prevState.all + 1 };
      //   });
      localResult = 0;
      setResult(0);
      //   if (testIdNumber) {
      //     if (testsToRedo.filter((a) => a === testIdNumber).length > 0) return;
      //     testsToRedo.push(testIdNumber);
      //     localStorage.setItem("testsToRedo", JSON.stringify(testsToRedo));
      //   }
    }
    // console.log("asd", result, userBid, "asd");
    const { points, tagId, tagStorage, efficiency } = updateStats(
      drawnTest?.id,
      localResult,
      drawnTest?.tag,
      hasFinished,
      drawnTest?.level
    );
    // console.log(points, tagId, tagStorage, efficiency);
    const isBadgeActive = checkIfBadgeActive(tagStorage.right, tagStorage.all);
    dispatch(setEfficiency(efficiency));
    dispatch(setPoints(points));
    dispatch(
      updatePossibleTagsBadge({
        tag: tagId,
        isBadgeActive: isBadgeActive,
        percent: Math.floor((tagStorage.right / tagStorage.all) * 100),
        all: tagStorage.all,
      })
    );
    updateAllTests(localResult);
  }, [userBid]);
  //   console.log(complexAnswers[reversedDepth]);
  //   useEffect(() => {
  //     console.log(result, testDepth, currentDepth);
  //   }, [result]);

  const shouldShowAnswer =
    result === 0 || result === 0.5 || testDepth < currentDepth || altGood;

  return (
    <div className="bid">
      {testDepth > currentDepth && (
        <p className="bid__correct">Dobrze! Kontynuuj.</p>
      )}
      {/* {!isLoading && correctAnswers && showExplanation && !tested && (
        <>
          <h2>{`${correctAnswers.bidString}`}</h2>
          <p>{`${correctAnswers.explanationString}`}</p>
        </>
      )} */}
      {!shouldShowAnswer && (
        <BiddingUI
          handleUserBid={(b) => setUserBid(b)}
          //   handleBidNumber={(n) => setUserBidNumber(n)}
          //   handleBidTrump={(t) => setUserBidTrump(t)}
        />
      )}
      {/* { <p>{`current depth ${currentDepth}`}</p>} */}
      {shouldShowAnswer && (
        <>
          <p className={`tested__result tested__result--${result}`}>
            {resultDictionary[result === null ? 1 : result]}
          </p>
          <div className="tested">
            {(result === 0 || result === 0.5) && (
              <div>
                <p className="tested__text">TWOJA ODPOWIEDŹ</p>
                <SequenceItem
                  item={userBid}
                  index={0}
                  classes={`sequence-item sequence-item-show  sequence-item--${userBid?.number} sequence-item--${userBid?.enum} sequence-item--explanation`}
                />
                {/* <span className="big">
                {userBidNumber <= 0
                  ? getBidByValue(userBidNumber)
                  : ` ${userBidNumber}${
                      userBidTrump === "BA"
                        ? "BA"
                        : colorsLibrary.get(userBidTrump)
                    }`}
              </span> */}
              </div>
            )}
            <Explanation result={result} answer={currentAnswers} />
          </div>
          {(result === 0 || result === 0.5) && (
            <button
              className="button button__repeat"
              onClick={() => {
                reset();
                const prevIndex = parseInt(drawnTest?.id.replace(/\D/g, ""));
                dispatch(setTest(`test-${prevIndex}`));
              }}
            >
              Ponów <Repeat />
            </button>
          )}
          <button
            className="button button__next"
            onClick={() => {
              reset();
              const prevIndex = filteredTests.findIndex((item) => {
                return item?.id == drawnTest?.id;
              });
              let nextId = 1;
              if (prevIndex < filteredTests.length) {
				nextId =  parseInt( filteredTests[prevIndex + 1]?.id.replace(/\D/g, ""));
              }

              dispatch(setTest(`test-${nextId}`));
            }}
          >
            Kolejne <Arrow />
          </button>
          <button
            className="button"
            onClick={() => {
              reset();
              const drawnTestId = `test-${Math.floor(
                Math.random() * filteredTests?.length
              )}`;
              dispatch(setTest(drawnTestId));
            }}
          >
            Losuj <Draw />
          </button>
        </>
      )}
      {/* <div className="stats">
        <p className="big">Twoje statystyki:</p>
        <Stats
          statsDwustronna={statsDwustronna}
          statsJednostronna={statsJednostronna}
          statsDrury={statsDrury}
          statsMagister={statsMagister}
          statsOdwrotka={statsOdwrotka}
          statsBlackwood={statsBlackwood}
        />
      </div> */}
    </div>
  );
};
