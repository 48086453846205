import { suitsEnum } from "../../helpers.ts";

type SequenceItemProps = {
  classes: string;
  item: any;
  index: number;
};

export const SequenceItem = (props: SequenceItemProps) => {
  const { classes, item, index } = props;

  return (
    <div className={classes} key={`${item?.string}-${index}`}>
      <div className="sequence__bid-wrapper">
        {!item?.number ? (
          <span className="sequence__special">{item?.string}</span>
        ) : (
          <>
            <span className="sequence__number ">
              {item?.number || item?.string}
            </span>
            {Object.values(suitsEnum).includes(item?.enum) ? (
              <img src={`./${item?.enum}.svg`} />
            ) : (
              item?.enum
            )}
          </>
        )}
      </div>
    </div>
  );
};
