import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllTestsDrawer,
  setTest,
  setFilters,
} from "../../redux/biddingSlice.ts";
import { Draw } from "./Draw.tsx";
import "./Drawer.css";
import { Drawer } from "./Drawer.tsx";
import type { RootState } from "./redux/store.ts";
import { TestItem } from "./TestItem.tsx";

const LEVELS = ["level-1", "level-2", "level-3"];

export const AllTestsDrawer = () => {
  const {
    filteredTests,
    possibleTags,
    filters,
    allTestsDrawerOpen,
  } = useSelector((state: RootState) => state.bidding);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [localFilters, setLocalFilters] = useState(filters);
  const [filtersInited, setFiltersInited] = useState(false);
  const [height, setHeight] = useState(0);
  const [heightAllTests, setHeightAllTests] = useState(0);

  const allPossibleTags = possibleTags.map((t) => t.id);
  //   console.log("allPossibleTags", allPossibleTags);

  const toggleTag = (tag: string) => {
    // console.log(tag);
    //TODO: type this
    //tap while all is on
    if (
      localFilters.filter((t) => !LEVELS.includes(t)).length ===
        possibleTags.length &&
      !LEVELS.includes(tag)
    ) {
      setLocalFilters((prev) => [
        ...prev.filter((i) => i === tag || LEVELS.includes(i)),
      ]);
      return;
    }

    //tap while all is off except the one
    // console.log(localFilters.filter((t) => !LEVELS.includes(t))[0], tag);
    if (
      !LEVELS.includes(tag) &&
      localFilters.filter((t) => !LEVELS.includes(t)).length === 1 &&
      localFilters.filter((t) => !LEVELS.includes(t))[0] === tag
    ) {
      //   console.log("localFilters", localFilters);
      setLocalFilters((prev) => [
        ...prev.filter((i) => LEVELS.includes(i)),
        ...allPossibleTags,
      ]);
      return;
    }

    if (localFilters.includes(tag)) {
      //   console.log("yes this is true");
      setLocalFilters((prev) => prev.filter((i) => i !== tag));
      //   currentFilters.filter((t) => {
      //     console.log(t !== tag);
      //     return t !== tag;
      //   });
      //   console.log(currentFilters.length);
    } else {
      setLocalFilters((prev) => [...prev, tag]);
      //   console.log("no this is false");
    }
    // console.log(currentFilters.length)
  };

  useEffect(() => {
    // console.log("localFilters", localFilters);
    dispatch(setFilters(localFilters));
  }, [localFilters]);

  useEffect(() => {
    // console.log("localFilters", localFilters);
    if (filtersInited) return;
    if (filters.length !== 0) {
      setLocalFilters(filters);
      setFiltersInited(true);
    }
  }, [filters, filtersInited]);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [allTestsDrawerOpen]); //empty dependency array so it only runs once at render

  useEffect(() => {
    setHeightAllTests(window.innerHeight - height - 100);
  }, [height]); //empty dependency array so it only runs once at render
  return (
    <Drawer
      isOpened={allTestsDrawerOpen}
      onClose={() => dispatch(closeAllTestsDrawer())}
    >
      <div className="allTests__wrapper">
        <div className="allTests__tags" ref={ref}>
          {possibleTags.map((tag) => (
            <button
              onClick={() => {
                toggleTag(tag?.id);
              }}
              key={tag.id}
              className={`allTests__tag ${
                filters.includes(tag?.id) ? "" : "inactive"
              }`}
            >
              {tag.text}
            </button>
          ))}
          {/* //TODO: write map */}
          <div className="allTags__levels">
            <button
              onClick={() => {
                toggleTag("level-1");
              }}
              key={"level-1"}
              className={`allTests__tag allTests__tag--level-1 ${
                filters.includes("level-1") ? "" : "inactive"
              }`}
            >
              ŁATWY
            </button>
            <button
              onClick={() => {
                toggleTag("level-2");
              }}
              key={"level-2"}
              className={`allTests__tag allTests__tag--level-2 ${
                filters.includes("level-2") ? "" : "inactive"
              }`}
            >
              ŚREDNI
            </button>
            <button
              onClick={() => {
                toggleTag("level-3");
              }}
              key={"level-3"}
              className={`allTests__tag allTests__tag--level-3 ${
                filters.includes("level-3") ? "" : "inactive"
              }`}
            >
              TRUDNY
            </button>
          </div>
        </div>
        <div className="allTests__container">
          <div
            className="allTests__container-inner"
            style={{ height: heightAllTests }}
          >
            <button
              className="allTests__draw"
              onClick={() => {
                const drawnTestId =`test-${Math.floor(Math.random() * filteredTests?.length)}`
                dispatch(setTest(drawnTestId));
                dispatch(closeAllTestsDrawer());
              }}
            >
              Losuj <Draw />
            </button>
            {filteredTests.map((item) => (
              <TestItem item={item} key={item.id} />
            ))}
            {filteredTests.length === 0 && (
              <p className="allTests__noTests">
                Brak testów spełniających podane kryteria
              </p>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
