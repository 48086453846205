import { useMemo } from "react";
import { Card } from "../Card/Card.tsx";

export const Cards = (props) => {
  const { cards } = props;

  return (
    <div className="cards-wrapper">
      <div className="cards">
        {cards && cards.map((card, index) => (
          <Card card={card} index={index} key={index} />
        ))}
      </div>
    </div>
  );
};
