import { Close } from "./Close.tsx";
import "./Drawer.css";

export const Drawer = ({ isOpened, onClose, children }) => {
  return (
    <div className={`drawer ${isOpened ? "" : "drawer--closed"}`}>
      <div className="drawer__close-wrapper">
        <button onClick={onClose} className="drawer__close">
          <Close />
        </button>
      </div>
      {children}
    </div>
  );
};
