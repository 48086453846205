interface ISeats {
  started: "N" | "E" | "S" | "W";
}

export const Seats = (props:ISeats) => {
  const { started } = props;
  return (
    <>
      <p className="seat seat-N">
        N
        <span className={`dealer ${started === "N" ? "show" : ""}`}>
          DEALER
        </span>
      </p>
      <p className="seat seat-E">
        E
        <span
          className={`dealer dealer-bottom-right ${
            started === "E" ? "show" : ""
          }`}
        >
          DEALER
        </span>
      </p>
      <p className="seat seat-S">
        S
        <span className={`dealer  ${started === "S" ? "show" : ""}`}>
          DEALER
        </span>
      </p>
      <p className="seat seat-W">
        W
        <span
          className={`dealer dealer-bottom-left ${
            started === "W" ? "show" : ""
          }`}
        >
          DEALER
        </span>
      </p>
    </>
  );
};
