import { suitsEnum } from "../helpers.ts";

export const fixCardsValues = (suit: string, color: suitsEnum) => {
  const lowCards = [9, 8, 7, 6, 5, 4, 3, 2];
  const shuffledLowCards = lowCards.sort((a, b) => 0.5 - Math.random());
  const suitArray = suit.trim().replace(/(10)/, "0").split("");
  const suitHonors = suitArray
    .filter((card) => card !== "x")
    .map((card) => (card === "10" ? "0" : card));
  const suitLowCards = suitArray
    .filter((card) => card === "x")
    .map(() => shuffledLowCards.pop())
    .sort((a, b) => b - a);
  const mergedSuit = [...suitHonors, ...suitLowCards];
  const suitWithColor = mergedSuit.map((card) => card + color.charAt(0));
  return suitWithColor;
};

export const fixCards = (s, h, c, d) => {
  const spades = fixCardsValues(s, suitsEnum.SPADES);
  const hearts = fixCardsValues(h, suitsEnum.HEARTS);
  const clubs = fixCardsValues(c, suitsEnum.CLUBS);
  const diamonds = fixCardsValues(d, suitsEnum.DIAMONDS);

  return { spades, hearts, clubs, diamonds };
};
